<script>
	import * as Sentry from '@sentry/svelte';
	import { BrowserTracing } from '@sentry/tracing';
	Sentry.init({
		dsn: 'https://86ade0fe300a4862a212dd14def00eed@o1399796.ingest.sentry.io/6727676',
		integrations: [new BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
</script>

<slot />
